/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react';
import './src/styles/main.scss';
import Navbar from './src/components/shared/navbar/navbar';
import Footer from './src/components/shared/footer/footer';
import Cookies from './src/components/shared/cookies/cookies';
import { Helmet } from 'react-helmet';

export function shouldUpdateScroll({ routerProps: { location }, getSavedScrollPosition }) {
  const { hash } = location;
  if (hash) {
    return true;
  }
  setTimeout(() => window.scrollTo(0, 0), 10);
  return false;
}

export const wrapRootElement = ({ element }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Business Glass oferuje innowacyjne warsztaty biznesowe, które łączą edukację biznesową z pasją do wina. Poprzez inspirujące analogie i historie z obu światów, pomagamy uczestnikom rozwijać umiejętności i odkrywać nowe strategie biznesowe."
      />
    </Helmet>
    <Navbar />
    {element}
    <Cookies />
    <Footer />
  </>
);
