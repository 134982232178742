// extracted by mini-css-extract-plugin
export var copy = "footer-module--copy--96a57";
export var copyrightWrap = "footer-module--copyrightWrap--ce689";
export var footer = "footer-module--footer--bb1f4";
export var footerOuter = "footer-module--footerOuter--67dcb";
export var link = "footer-module--link--e9b94";
export var logo = "footer-module--logo--ace7e";
export var mainWrap = "footer-module--mainWrap--74e26";
export var menu = "footer-module--menu--b9dbb";
export var menuItem = "footer-module--menuItem--354e7";
export var menuItemTitle = "footer-module--menuItemTitle--7eb7a";
export var menuWrap = "footer-module--menuWrap--e8ce2";
export var privacyWrap = "footer-module--privacyWrap--deb63";
export var socialsItem = "footer-module--socialsItem--cff72";
export var socialsWrap = "footer-module--socialsWrap--93717";