import React, { useEffect, useRef, useState } from 'react';
import * as styles from './navbar.module.scss';
import Logo from '../logo/logo';
import { Link } from 'gatsby';
import classNames from 'classnames';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const burgerRef = useRef(null);
  const menuRef = useRef(null);

  const handleBurgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target) && menuOpen) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > 100 ? setScrolled(true) : setScrolled(false);
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  return (
    <nav
      ref={navbarRef}
      className={classNames(styles.navbar, {
        [styles.activeDesktop]: scrolled,
        [styles.activeMobile]: menuOpen,
      })}
    >
      <div className={styles.navWrap}>
        <Logo />
        <div
          ref={menuRef}
          className={classNames(styles.menuContainer, { [styles.activeMobile]: menuOpen })}
        >
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <Link
                to="/szkolenia"
                className="linkNav"
                activeClassName="active"
                onClick={handleBurgerClick}
              >
                Szkolenia
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/rola-wina-w-projekcie"
                className="linkNav"
                activeClassName="active"
                onClick={handleBurgerClick}
              >
                Rola wina w projekcie
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/o-nas"
                className="linkNav"
                activeClassName="active"
                onClick={handleBurgerClick}
              >
                O nas
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                to="/kontakt"
                className="linkNav"
                activeClassName="active"
                onClick={handleBurgerClick}
              >
                Kontakt
              </Link>
            </li>
          </ul>
          <Link to="/kontakt" className="button outline small" onClick={handleBurgerClick}>
            Wyślij wiadomość
          </Link>
        </div>
        <button
          ref={burgerRef}
          type="button"
          className={classNames(styles.burger, { [styles.activeMobile]: menuOpen })}
          onClick={handleBurgerClick}
          aria-label="Menu"
        ></button>
      </div>
    </nav>
  );
};
export default Navbar;
