exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rola-wina-w-projekcie-js": () => import("./../../../src/pages/rola-wina-w-projekcie.js" /* webpackChunkName: "component---src-pages-rola-wina-w-projekcie-js" */),
  "component---src-pages-styleguide-js": () => import("./../../../src/pages/styleguide.js" /* webpackChunkName: "component---src-pages-styleguide-js" */),
  "component---src-pages-szkolenia-js": () => import("./../../../src/pages/szkolenia.js" /* webpackChunkName: "component---src-pages-szkolenia-js" */),
  "component---src-pages-szkolenie-biznesowe-marzenia-przewodnik-dla-kazdego-js": () => import("./../../../src/pages/szkolenie/biznesowe-marzenia-przewodnik-dla-kazdego.js" /* webpackChunkName: "component---src-pages-szkolenie-biznesowe-marzenia-przewodnik-dla-kazdego-js" */),
  "component---src-pages-szkolenie-od-kultury-przerwy-do-regionalnej-tradycji-js": () => import("./../../../src/pages/szkolenie/od-kultury-przerwy-do-regionalnej-tradycji.js" /* webpackChunkName: "component---src-pages-szkolenie-od-kultury-przerwy-do-regionalnej-tradycji-js" */),
  "component---src-pages-szkolenie-opowiesci-o-niespodziewanych-zwyciestwach-js": () => import("./../../../src/pages/szkolenie/opowiesci-o-niespodziewanych-zwyciestwach.js" /* webpackChunkName: "component---src-pages-szkolenie-opowiesci-o-niespodziewanych-zwyciestwach-js" */),
  "component---src-pages-szkolenie-przykladowe-szkolenie-js": () => import("./../../../src/pages/szkolenie/przykladowe-szkolenie.js" /* webpackChunkName: "component---src-pages-szkolenie-przykladowe-szkolenie-js" */)
}

