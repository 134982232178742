import React from 'react';
import * as styles from './logo.module.scss';
import logo from '../../../images/logo.webp';
import classNames from 'classnames';
import { Link } from 'gatsby';

const Logo = ({ className }) => {
  return (
    <Link to="/" className={classNames(styles.logoWrap, className)}>
      <img src={logo} className={styles.logo} alt="Business Glass" width={143} height={48} />
    </Link>
  );
};

export default Logo;
