import React from 'react';
import * as styles from './footer.module.scss';
import Logo from '../logo/logo';
import classNames from 'classnames';
import { Link } from 'gatsby';
import linkedin from '../../../images/socials/linkedin.svg';
import twitter from '../../../images/socials/twitter.svg';
import facebook from '../../../images/socials/facebook.svg';

const Footer = () => (
  <footer className={styles.footerOuter}>
    <div className={styles.footer}>
      <div className={styles.mainWrap}>
        <Logo className={styles.logo} />
        <div className={styles.menuWrap}>
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <p className={styles.menuItemTitle}>Oferta</p>
            </li>
            <li className={styles.menuItem}>
              <Link to="/szkolenia" className={classNames('linkNav', styles.link)}>
                Szkolenia
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link to="/rola-wina-w-projekcie" className={classNames('linkNav', styles.link)}>
                Rola wina w projekcie
              </Link>
            </li>
          </ul>
          <ul className={styles.menu}>
            <li className={styles.menuItem}>
              <p className={styles.menuItemTitle}>Business glass</p>
            </li>
            <li className={styles.menuItem}>
              <Link to="/o-nas" className={classNames('linkNav', styles.link)}>
                O nas{' '}
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link to="/kontakt" className={classNames('linkNav', styles.link)}>
                Kontakt{' '}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.privacyWrap}>
        <Link to="/polityka-prywatnosci" className={classNames('linkNav', styles.link)}>
          Polityka Prywatności
        </Link>
        <Link to="/regulamin" className={classNames('linkNav', styles.link)}>
          Regulamin
        </Link>
      </div>

      <div className={styles.copyrightWrap}>
        <p className={classNames('body-14', styles.copy)}>Copyright &copy; 2024 Business Glass</p>

        <div className={styles.socialsWrap}>
          <Link
            to="https://www.linkedin.com/company/businessglass/"
            className={styles.socialsItem}
            target="_blank"
          >
            <img src={linkedin} alt="Linkedin" />
          </Link>
          {/*<Link to="/" className={styles.socialsItem} target="_blank">*/}
          {/*  <img src={twitter} alt="X" />*/}
          {/*</Link>*/}
          {/*<Link to="/" className={styles.socialsItem} target="_blank">*/}
          {/*  <img src={facebook} alt="Facebook" />*/}
          {/*</Link>*/}
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
