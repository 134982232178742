import React, { useEffect, useRef } from 'react';
import * as styles from './cookies.module.scss';
import { Link } from 'gatsby';
import classNames from 'classnames';

const Cookies = () => {
  const cookiesRef = useRef();
  const acceptCookiesRef = useRef();

  useEffect(() => {
    if (!localStorage.getItem('cookiesAccepted')) {
      cookiesRef.current.classList.add(styles.active);
    }
  }, []);

  const acceptCookies = () => {
    cookiesRef.current.classList.remove(styles.active);
    localStorage.setItem('cookiesAccepted', true);
  };

  return (
    <div className={classNames(styles.cookiesWrap)} ref={cookiesRef}>
      <p className={classNames('body-16', styles.text)}>
        Ta strona korzysta z plików cookie w celu świadczenia usług na najwyższym poziomie. Dalsze
        korzystanie ze Strony oznacza, że zgadzasz się na korzystanie z plików cookie.
      </p>
      <div className={styles.buttonsWrap}>
        <button
          className={classNames('button small', styles.button)}
          ref={acceptCookiesRef}
          onClick={acceptCookies}
        >
          Akceptuje
        </button>
        <Link to="/polityka-prywatnosci" className={classNames('button small', styles.button)}>
          Polityka prywatności
        </Link>
      </div>
    </div>
  );
};

export default Cookies;
